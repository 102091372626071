<template>
  <v-dialog :value="value" persistent width="750">
    <v-card>
      <v-toolbar dark flat color="#1C3144">
        <v-toolbar-title>{{this.selectedBatch.manufacturer}}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon @click="$emit('update:value', false)"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-toolbar flat>
        <v-toolbar-title>Payment History</v-toolbar-title>
        <v-row dense class="mt-3">
          <v-spacer/>
          <v-col md="4" lg="4" xl="4">
            <v-text-field dense outlined label="Payment Amount" prefix="$" type="number" v-model="new_payment.payment.amount"/>
          </v-col>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn :disabled="!new_payment.payment.amount" v-on="on" height="38" color="#0d68a5" class="white--text mt-1 ml-2" @click="saveInvoicePayment">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Add Payment</span>
          </v-tooltip>
        </v-row>
      </v-toolbar>
      <v-divider/>
      <v-container class="ma-0 pa-0" fluid>
        <v-data-table
          :headers="headers"
          :height="tableSize"
          :items="payments"
          dense
          fixed-header
          hide-default-footer
          no-data-text="No Payments to Display"
        >
          <template v-slot:[`item.payment_date`]="{ item }">
            {{ moment(item.payment_date).format($config.date_display) }}
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            - ${{ item.amount | currency }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon small class="red--text" @click="paymentToDelete=item, confirmDelete=true">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>

        </v-data-table>
      </v-container>
      <v-divider/>
      <v-toolbar flat>
        <v-spacer/>
        <v-toolbar-title class="text-body-1">Total Paid: ${{total_paid | currency}}</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <v-dialog justify="center" :value="confirmDelete" persistent max-width="500">
      <v-card color=#fafafa>
        <v-card-title class="text-capitalize">
          <v-icon left large color="primary">mdi-help-rhombus</v-icon>
          Delete Payment?
          <v-spacer />
        </v-card-title>
        <v-card-text class="px-12">
          Are you sure you want to delete payment amount of ${{paymentToDelete.amount | currency}}?
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="$emit('update:value', false)">Cancel</v-btn>
          <v-btn class="grey lighten-2 ml-2" @click="deleteInvoicePayment(paymentToDelete)">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-dialog>
</template>

<script>
import { tableSettings } from '@/mixins/table'
import { notification } from '@/mixins/notification'

export default {
  name: 'InvoicePayments',

  filters: {
    currency: function (value) {
      if (!value) return '0.00'
      return parseFloat(value).toFixed(2)
    }
  },

  mixins: [tableSettings, notification],

  data () {
    return {
      confirmDelete: false,
      loading: true,
      total_paid: '',
      payments: [],
      headers: [
        { width: '5%' },
        { text: 'Date', class: 'black--text', value: 'payment_date' },
        { text: 'Amount', class: 'black--text', value: 'amount' },
        { text: 'Actions', align: 'center', class: 'black--text', value: 'actions' }
      ],
      new_payment: {
        invoice_number: this.selectedBatch.invoice_number,
        payment: {
          amount: '',
          payment_date: this.moment().format(this.$config.date_format)
        }
      },
      paymentToDelete: {}
    }
  },

  props: ['value', 'selectedBatch'],

  watch: {
    value: {
      immediate: true,
      handler () {
        if (this.value) {
          this.getInvoicePayments()
        }
      }
    }
  },

  methods: {
    async getInvoicePayments () {
      const response = await this.$invoicing.getInvoicePayments(this.selectedBatch.invoice_number)
      this.payments = response.payments
      this.total_paid = response.total_paid
      this.loading = false
    },

    async saveInvoicePayment () {
      this.new_payment.payment.amount = parseFloat(this.new_payment.payment.amount)
      try {
        await this.$invoicing.saveInvoicePayment(this.new_payment)
        this.notify('success', "Successfully Saved Payment")
      } catch {
        this.notify('error', "Error Saving Payment")
      } finally {
        this.getInvoicePayments()
      }
      this.new_payment.payment.amount = ''
      this.$emit('refresh', this.selectedBatch)
    },

    async updateInvoicePayment () {
      try {
        await this.$invoicing.updateInvoicePayment()
        this.notify('success', "Successfully Updated Payment")
      } catch {
        this.notify('error', "Error Updating Payment")
      } finally {
        this.getInvoicePayments()
      }
    },

    async deleteInvoicePayment (item) {
      this.confirmDelete = false
      let payment = {
        invoice_number: this.selectedBatch.invoice_number,
        payment: {
          amount: item.amount,
          payment_date: item.payment_date,
          payment_id: item.payment_id,
        }
      }

      try {
        await this.$invoicing.deleteInvoicePayment(payment)
        this.notify('success', "Successfully Deleted Payment")
      } catch {
        this.notify('error', "Error Deleting Payment")
      } finally {
        this.getInvoicePayments()
      }
      this.paymentToDelete = {}
      this.$emit('refresh', this.selectedBatch)
    }
  }
}
</script>
