var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", width: "750" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", flat: "", color: "#1C3144" } },
            [
              _c("v-toolbar-title", [
                _vm._v(_vm._s(this.selectedBatch.manufacturer))
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:value", false)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-toolbar",
            { attrs: { flat: "" } },
            [
              _c("v-toolbar-title", [_vm._v("Payment History")]),
              _c(
                "v-row",
                { staticClass: "mt-3", attrs: { dense: "" } },
                [
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    { attrs: { md: "4", lg: "4", xl: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          label: "Payment Amount",
                          prefix: "$",
                          type: "number"
                        },
                        model: {
                          value: _vm.new_payment.payment.amount,
                          callback: function($$v) {
                            _vm.$set(_vm.new_payment.payment, "amount", $$v)
                          },
                          expression: "new_payment.payment.amount"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "white--text mt-1 ml-2",
                                    attrs: {
                                      disabled: !_vm.new_payment.payment.amount,
                                      height: "38",
                                      color: "#0d68a5"
                                    },
                                    on: { click: _vm.saveInvoicePayment }
                                  },
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-plus")])],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Add Payment")])]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-container",
            { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  height: _vm.tableSize,
                  items: _vm.payments,
                  dense: "",
                  "fixed-header": "",
                  "hide-default-footer": "",
                  "no-data-text": "No Payments to Display"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.payment_date",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm
                                  .moment(item.payment_date)
                                  .format(_vm.$config.date_display)
                              ) +
                              " "
                          )
                        ]
                      }
                    },
                    {
                      key: "item.amount",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " - $" +
                              _vm._s(_vm._f("currency")(item.amount)) +
                              " "
                          )
                        ]
                      }
                    },
                    {
                      key: "item.actions",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-btn",
                            {
                              staticClass: "red--text",
                              attrs: { icon: "", small: "" },
                              on: {
                                click: function($event) {
                                  ;(_vm.paymentToDelete = item),
                                    (_vm.confirmDelete = true)
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-trash-can-outline")])],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-toolbar",
            { attrs: { flat: "" } },
            [
              _c("v-spacer"),
              _c("v-toolbar-title", { staticClass: "text-body-1" }, [
                _vm._v(
                  "Total Paid: $" + _vm._s(_vm._f("currency")(_vm.total_paid))
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            justify: "center",
            value: _vm.confirmDelete,
            persistent: "",
            "max-width": "500"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { color: "#fafafa" } },
            [
              _c(
                "v-card-title",
                { staticClass: "text-capitalize" },
                [
                  _c(
                    "v-icon",
                    { attrs: { left: "", large: "", color: "primary" } },
                    [_vm._v("mdi-help-rhombus")]
                  ),
                  _vm._v(" Delete Payment? "),
                  _c("v-spacer")
                ],
                1
              ),
              _c("v-card-text", { staticClass: "px-12" }, [
                _vm._v(
                  " Are you sure you want to delete payment amount of $" +
                    _vm._s(_vm._f("currency")(_vm.paymentToDelete.amount)) +
                    "? "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("update:value", false)
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "grey lighten-2 ml-2",
                      on: {
                        click: function($event) {
                          return _vm.deleteInvoicePayment(_vm.paymentToDelete)
                        }
                      }
                    },
                    [_vm._v("Confirm")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }